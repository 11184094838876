import { navigate } from 'gatsby'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default () => {
    const history = useHistory()
    useEffect(() => {
        const url = `/products/all?groupby=sectionNumber%2520false%252CproductType%2520false&sortby=sectionNumber%252CproductType%252Ctype%252ClastUpdated%2520desc&ia=true&defaultFilter=true`
        history.push(url)
        navigate(url)
    }, [])
    return null
}
